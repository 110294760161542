<template>
    <div class="search">
        <!-- 不展开的亚子 -->
        <div>
            <el-card class="top">
                <div class="flex-sb title-search">
                    <el-row style="width: 100%" type="flex" align="middle" justify="start">
                        <el-col :span="1">
                            <div style="text-align: center">筛选：</div>
                            <!-- 打开显示select选中的信息 -->
                        </el-col>
                        <el-col :span="16">
                            <!-- 左边筛选的 -->
                            <div class="flex" style="align-items: center">
                                <!-- <transition name="el-fade-in-linear"> -->
                                <!-- <transition name="el-fade-in-linear"> -->
                                <div
                                    v-if="isOpen"
                                    class="flex-sb"
                                    style="
										white-space: nowrap;
										overflow-y: hidden;
									"
                                >
                                    <!-- 这是资质的区域 -->
                                    <div v-if="chooseQuality.length" class="flex-sb">
                                        <div
                                            v-for="(item, index) in chooseQuality"
                                            :key="index"
                                            class="selItem"
                                        >
                                            {{ item | qualityName }}
                                            <i
                                                class="el-icon-close isHover"
                                                @click="delQuality(item)"
                                            />
                                        </div>
                                    </div>
                                    <!-- 这是金额的范围 -->
                                    <div v-if="chooseMoney" class="selItem">
                                        {{ chooseMoney | moneyName }}
                                        <i
                                            class="el-icon-close isHover"
                                            @click="delMoney()"
                                        />
                                    </div>
                                    <!-- 这是未确认的范围 -->
                                    <div v-if="choiceUnconfirm" class="selItem">
                                        {{ choiceUnconfirm | unconfirmName }}
                                        <i
                                            class="el-icon-close isHover"
                                            @click="delUnconfirm()"
                                        />
                                    </div>

                                    <!-- 这是线索状态的取值 -->
                                    <div v-if="choiceClueStatus" class="selItem">
                                        {{ choiceClueStatus | clueStatusName }}
                                        <i
                                            class="el-icon-close isHover"
                                            @click="delClueStatus()"
                                        />
                                    </div>
                                    <!-- 这是新老客户的范围 -->
                                    <div v-if="chooseNewOrOld" class="selItem">
                                        {{ chooseNewOrOld | newOrOldName }}
                                        <i
                                            class="el-icon-close isHover"
                                            @click="delnewOrOld()"
                                        />
                                    </div>

                                    <!-- 这是客户跟进阶段的范围 -->
                                    <div v-if="clientStage" class="selItem">
                                        {{ clientStage | stageName }}
                                        <i
                                            class="el-icon-close isHover"
                                            @click="delClientStage"
                                        />
                                    </div>
                                    <!-- 这是获得方式的范围 -->
                                    <div v-if="transfer" class="selItem">
                                        {{ transfer | transferName }}
                                        <i
                                            class="el-icon-close isHover"
                                            @click="delTransfer"
                                        />
                                    </div>
                                    <!-- 这是意向度的范围 -->
                                    <div v-if="intentVal" class="selItem">
                                        {{ intentVal | intentValName }}
                                        <i
                                            class="el-icon-close isHover"
                                            @click="delIntentVal"
                                        />
                                    </div>

                                    <!-- 这是资质星级的范围 -->
                                    <div v-if="starLevel" class="selItem">
                                        {{ starLevel | starLevelName }}
                                        <i
                                            class="el-icon-close isHover"
                                            @click="delQualityStar"
                                        />
                                    </div>

                                    <!-- 这是封存的范围 -->
                                    <div v-if="sealUpVal" class="selItem">
                                        {{ sealUpVal | sealUpVallName }}
                                        <i
                                            class="el-icon-close isHover"
                                            @click="delSealPool"
                                        />
                                    </div>
                                </div>
                                <!-- 未打开显示select的输入框 -->
                                <div v-if="!isOpen">
                                    <el-form :inline="true" :model="searchForm">
                                        <!-- 资质筛选 -->
                                        <el-form-item v-if="labelArr.includes('客户资质')">
                                            <el-select
                                                v-model="chooseQuality"
                                                multiple
                                                collapse-tags
                                                default-first-option
                                                placeholder="客户资质"
                                                size="small"
                                                clearable
                                                @change="selQuality"
                                            >
                                                <el-option
                                                    v-for="item in quality"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                        <!-- 金额筛选 -->
                                        <el-form-item v-if="labelArr.includes('资金需求')">
                                            <el-select
                                                v-model="chooseMoney"
                                                placeholder="资金需求"
                                                size="small"
                                                clearable
                                                @change="selMoney"
                                            >
                                                <el-option
                                                    v-for="item in money"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                        <!-- 未确认筛选 -->
                                        <el-form-item v-if="labelArr.includes('未确认标签')">
                                            <el-select
                                                v-model="choiceUnconfirm"
                                                placeholder="未确认条件"
                                                size="small"
                                                clearable
                                                @change="selUnconfirm"
                                                @clear="unconfirmClear"
                                            >
                                                <el-option
                                                    v-for="item in unconfirmed"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>

                                        <!-- 线索状态 -->
                                        <el-form-item v-if="labelArr.includes('线索状态')">
                                            <el-select
                                                v-model="choiceClueStatus"
                                                placeholder="线索状态"
                                                size="small"
                                                clearable
                                                @change="selClueStatus"
                                                @clear="clearClueStatus"
                                            >
                                                <el-option
                                                    v-for="item in clueStatus"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                        <!-- 新老客筛选 -->
                                        <el-form-item v-if="labelArr.includes('新老客')">
                                            <el-select
                                                v-model="chooseNewOrOld"
                                                placeholder="新老客户"
                                                size="small"
                                                clearable
                                                @change="selNewOrOld"
                                            >
                                                <el-option
                                                    v-for="item in newOrOld"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                        <!-- 跟进阶段 -->
                                        <el-form-item v-if="labelArr.includes('跟进阶段')">
                                            <el-select
                                                v-model="clientStage"
                                                placeholder="跟进阶段"
                                                size="small"
                                                clearable
                                                @change="selClientStage"
                                            >
                                                <el-option
                                                    v-for="item in clientStageArr"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                        <!-- 获得方式 -->
                                        <el-form-item v-if="labelArr.includes('获得方式')">
                                            <el-select
                                                v-model="transfer"
                                                placeholder="获得方式"
                                                size="small"
                                                clearable
                                                @change="selTransfer"
                                            >
                                                <el-option
                                                    v-for="item in transferArr"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                        <!-- 意向度 -->
                                        <el-form-item v-if="labelArr.includes('意向度')">
                                            <el-select
                                                v-model="intentVal"
                                                placeholder="意向度"
                                                size="small"
                                                clearable
                                                @change="selIntente"
                                                @clear="clearIntente"
                                            >
                                                <el-option
                                                    v-for="item in Intentionality"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                        <!-- 资质星级 -->
                                        <el-form-item v-if="labelArr.includes('资质星级')">
                                            <el-select
                                                v-model="starLevel"
                                                placeholder="资质星级"
                                                size="small"
                                                clearable
                                                @change="selStarLevel"
                                            >
                                                <el-option
                                                    v-for="item in starLevelArr"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                        <!-- 封存 -->
                                        <el-form-item v-if="labelArr.includes('封存')">
                                            <el-select
                                                v-model="sealUpVal"
                                                placeholder="封存"
                                                size="small"
                                                clearable
                                                @change="selSealUp"
                                            >
                                                <el-option
                                                    v-for="item in sealUpArr"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item v-if="labelArr.includes('标签')">
                                            <el-select
                                                v-model="tag"
                                                placeholder="无效"
                                                size="small"
                                                clearable
                                                @change="tagChange"
                                            >
                                                <el-option
                                                    v-for="item in tagArr"
                                                    :key="item.number"
                                                    :label="item.cnVal"
                                                    :value="item.number"
                                                />
                                            </el-select>
                                        </el-form-item>
                                    </el-form>
                                </div>
                                <!-- </transition> -->

                                <!--  -->
                            </div>
                        </el-col>
                        <el-col :span="7" style="margin-left: 1.16667%">
                            <div class="flex" style="justify-content: flex-end">
                                <div
                                    class="flex"
                                    :style="{ 'justify-content': isCustomer ? 'flex-end' : '' }"
                                >
                                    <el-select
                                        v-if="showDepartSea"
                                        v-model="departSeaVal"
                                        clearable
                                        size="small"
                                        style="
											margin-right: 5px;
											display: flex;
											align-items: center;
										"
                                        :placeholder="isCustomer ? '公司海' : '公司池'"
                                        @change="selDepartment"
                                    >
                                        <el-option
                                            v-for="item in departmentSea"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>

                                    <el-cascader
                                        v-if="teamCustomer"
                                        ref="cascaderHandle"
                                        v-model="sortVal"
                                        style="margin-right: 10px"
                                        size="small"
                                        :options="sortArr"
                                        :props="{ expandTrigger: 'hover', checkStrictly: true, label: 'name', value: 'id' }"
                                        placeholder="当前归属"
                                        clearable
                                        @change="cascaderHandle"
                                    />
                                    <!-- 线索公共池 时间选择 -->
                                    <el-select
                                        v-if="isPublic"
                                        v-model="timeSortVal"
                                        clearable
                                        size="small"
                                        style="
											margin-right: 5px;
											display: flex;
											align-items: center;
										"
                                        placeholder="默认排序"
                                        @change="selCluePublic"
                                    >
                                        <el-option
                                            v-for="item in timeSortArr"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </el-select>
                                    <!-- 客户时间排序 -->
                                    <el-select
                                        v-if="isCustomer"
                                        v-model="customerTimeSortVal"
                                        size="small"
                                        clearable
                                        style="
											margin-right: 5px;
											display: flex;
											align-items: center;
										"
                                        placeholder="默认排序"
                                        @change="selCustomer"
                                    >
                                        <el-option
                                            v-for="item in customerTimeSort"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </el-select>
                                </div>
                                <!-- <div class="flex" style="align-items:center;">
                  <div @click="showOpen" style="width: 30px;cursor: pointer;">
                    {{ isOpen ? "收起" : "展开" }}
                  </div>
                  <img
                    width="25px"
                    v-if="isOpen"
                    height="25px"
                    src="@/assets/common/close.svg"
                    alt=""
                    @click="showOpen"
                  />
                  <img
                    width="25px"
                    v-else
                    height="25px"
                    src="@/assets/common/open.svg"
                    alt=""
                    @click="showOpen"
                  />
                                </div>-->
                            </div>
                        </el-col>
                        <!-- <el-col :span="1" style="width: 5%;">
              
                        </el-col>-->
                    </el-row>
                </div>
            </el-card>
            <!-- 展开块-->
            <!-- <el-collapse-transition> -->
            <el-card v-show="isOpen" style="margin-top: 10px" class="bottom">
                <div v-if="labelArr.includes('客户资质')" class="flex item">
                    <span>客户资质</span>
                    <div class="flex item-info" style="margin-left: 31px">
                        <div
                            v-for="(item, index) in quality"
                            :key="index"
                            :class="[item.showGet ? 'every-item active' : 'every-item']"
                            @click="getItem(1, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>
                <div v-if="labelArr.includes('资金需求')" class="flex item">
                    <span>资金需求</span>
                    <div class="flex item-info" style="margin-left: 31px">
                        <div
                            v-for="(item, index) in money"
                            :key="index"
                            :class="[chooseMoney == item.number ? 'every-item active' : 'every-item']"
                            @click="getItem(2, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>
                <div v-if="labelArr.includes('未确认标签')" class="flex item">
                    <span>未确认标签</span>
                    <div class="flex item-info" style="margin-left: 17px">
                        <div
                            v-for="(item, index) in unconfirmed"
                            :key="index"
                            :class="[misCall == item.number ? 'every-item active' : 'every-item']"
                            @click="getItem(3, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>
                <div v-if="labelArr.includes('线索状态')" class="flex item">
                    <span>线索状态</span>
                    <div class="flex item-info" style="margin-left: 31px">
                        <div
                            v-for="(item, index) in clueStatus"
                            :key="index"
                            :class="[misClueStaus == item.number ? 'every-item active' : 'every-item']"
                            @click="getItem(10, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>
                <div v-if="labelArr.includes('新老客')" class="flex item">
                    <span>新老客</span>
                    <div class="flex item-info" style="margin-left: 45px">
                        <div
                            v-for="(item, index) in newOrOld"
                            :key="index"
                            :class="[chooseNewOrOld == item.number ? 'every-item active': 'every-item']"
                            @click="getItem(4, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>

                <div v-if="labelArr.includes('跟进阶段')" class="flex item">
                    <span>跟进阶段</span>
                    <div class="flex item-info" style="margin-left: 32px">
                        <div
                            v-for="(item, index) in clientStageArr"
                            :key="index"
                            :class="[clientStage == item.number ? 'every-item active' : 'every-item']"
                            @click="getItem(5, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>

                <div v-if="labelArr.includes('获得方式')" class="flex item">
                    <span>获得方式</span>
                    <div class="flex item-info" style="margin-left: 32px">
                        <div
                            v-for="(item, index) in transferArr"
                            :key="index"
                            :class="[transfer == item.number ? 'every-item active' : 'every-item']"
                            @click="getItem(6, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>

                <div v-if="labelArr.includes('意向度')" class="flex item">
                    <span>意向度</span>
                    <div class="flex item-info" style="margin-left: 45px">
                        <div
                            v-for="(item, index) in Intentionality"
                            :key="index"
                            :class="[misWanted == item.number ? 'every-item active' : 'every-item']"
                            @click="getItem(7, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>

                <div v-if="labelArr.includes('资质星级')" class="flex item">
                    <span>资质星级</span>
                    <div class="flex item-info" style="margin-left: 30px">
                        <div
                            v-for="(item, index) in starLevelArr"
                            :key="index"
                            :class="[starLevel == item.number ? 'every-item active' : 'every-item']"
                            @click="getItem(8, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>

                <div v-if="labelArr.includes('封存')" class="flex item">
                    <span>封存</span>
                    <div class="flex item-info" style="margin-left: 57px">
                        <div
                            v-for="(item, index) in sealUpArr"
                            :key="index"
                            :class="[sealUpVal == item.number ? 'every-item active' : 'every-item']"
                            @click="getItem(9, item)"
                        >{{ item.cnVal }}</div>
                    </div>
                </div>
            </el-card>
            <!-- </el-collapse-transition> -->
        </div>
    </div>
</template>

<script>
// import { number } from 'echarts/lib/export';
// import {departments} from '@/api/common.js';
import { departments_staff, companySea } from '@/api/public_content.js';
let that;
export default {
    props: {
        labelArr: {
            type: Array,
            default: () => [],
        },
        //线索排序状态显示
        isPublic: {
            type: Boolean,
            default: false,
        },
        //客户排序状态显示
        isCustomer: {
            type: Boolean,
            default: false,
        },
        //团队客户-公海客户的排序显示
        teamCustomer: {
            type: Boolean,
            default: false,
        },
        //显示公司池公司海
        showDepartSea: {
            type: Boolean,
            default: false,
        },
        showOpenClose: {
            type: Boolean,
            default: false,
        },
    },
    filters: {
        // 过滤资质选中的值
        qualityName(val) {
            let numStr = '';
            that.quality.forEach((item) => {
                if (item.number == val) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
        // 过滤金额的值
        moneyName(val) {
            let numStr = '';
            that.money.forEach((item) => {
                if (item.number == val) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
        unconfirmName(val) {
            let newVal = val != '未接' ? val : '0';
            let numStr = '';
            that.unconfirmed.forEach((item) => {
                if (item.number == newVal) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
        newOrOldName(val) {
            let numStr = '';
            that.newOrOld.forEach((item) => {
                if (item.number == val) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
        stageName(val) {
            let numStr = '';
            that.clientStageArr.forEach((item) => {
                if (item.number == val) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
        transferName(val) {
            let numStr = '';
            that.transferArr.forEach((item) => {
                if (item.number == val) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
        intentValName(val) {
            let newVal = val != '潜在' ? val : '0';
            let numStr = '';
            that.Intentionality.forEach((item) => {
                if (item.number == newVal) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
        starLevelName(val) {
            let newVal = val != '未接' ? val : '0';
            let numStr = '';
            that.starLevelArr.forEach((item) => {
                if (item.number == newVal) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
        sealUpVallName(val) {
            let numStr = '';
            that.sealUpArr.forEach((item) => {
                if (item.number == val) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
        clueStatusName(val) {
            let newVal = val != '新线索' ? val : '0';
            let numStr = '';
            that.clueStatus.forEach((item) => {
                if (item.number == newVal) {
                    numStr = item.cnVal;
                }
            });
            return numStr;
        },
    },
    data() {
        return {
            searchForm: {},
            /*选项数组*/
            quality: [], //资质的字典选项
            money: [], //金额筛选项
            unconfirmed: [], //线索未确认时间
            clueStatus: [], //线索跟进状态
            newOrOld: [], //新老客
            clientStageArr: [], //用户跟进阶段
            sortArr: [], //el-select排序数组
            timeSortArr: [], //时间排序
            customerTimeSort: [], //客户排序的值
            Intentionality: [], //意向度
            starLevelArr: [], //资质星级数组
            transferArr: [], //客户获得方式
            departmentSea: [], //公司池公司海
            sealUpArr: [], //封存选项
            tagArr: [],
            /*选项数组*/

            /*选中*/
            tag: '',
            chooseQuality: [], //资质选中数组
            chooseMoney: '', //选中的金额数组
            choiceUnconfirm: '', //选中未确认的值
            choiceClueStatus: '', //线索状态选中值
            chooseNewOrOld: '', //选中的新老客户值
            clientStage: '', //跟进阶段的值
            intentVal: '', //意向度的值
            sortVal: '', //得到的排序值
            timeSortVal: '', //线索选择时间排序的值
            customerTimeSortVal: '', //客户排序的值
            departSeaVal: '', //公司池公司海的值
            starLevel: '', //资质星级
            transfer: '', //获得方式
            staffId: '', // 选中员工id
            departmentId: '', //选中部门ID
            sealUpVal: '', //封存的值
            /*选中*/
            isOpen: false, //暂开收起
            misCall: NaN,
            screenWidth: '',
            intentActive: '', //意向度解决number为0的
            misWanted: NaN,
            misClueStaus: NaN, //线索状态选中
            // labelArr: ["客户资质", "资金需求", "未确认标签", "新老客"],
            // isPublic: false, //线索排序状态显示
            // isCustomer: false, //客户排序状态显示
            // teamCustomer: false, //团队客户-公海客户的排序显示
            // showDepartSea: true, //显示公司池公司海
        };
    },
    created() {
        that = this;
        // 获取选项
        that.getSelect();
    },
    mounted() {
        that.screenWidth = document.body.clientWidth;
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth;
            })();
        };
        // if(that.screenWidth < 1920){
        //   this.isOpen = true
        // }
    },
    watch: {
        // screenWidth(n) {
        //   // 监视屏幕值的变化
        //   if (!this.showOpenClose) {
        //     if (n < 1920) {
        //       this.isOpen = false;
        //     } else {
        //       this.isOpen = true;
        //     }
        //   }
        // },
    },
    methods: {
        //选择部门
        cascaderHandle(val) {
            this.$refs.cascaderHandle.dropDownVisible = false;
            if (val.length > 0) {
                let node =
                    this.$refs.cascaderHandle.panel.getNodeByValue(val).data;
                if (node.type == 1) {
                    this.staffId = node.id;
                    this.departmentId = '';
                } else {
                    this.staffId = '';
                    this.departmentId = node.id;
                }
            } else {
                this.staffId = '';
                this.departmentId = '';
            }
            this.submitData();
            // staff({ departmentId: val[val.length - 1] }).then((res) => {
            //   if (res.code == 0) {
            //     this.options1 = res.data.list;
            //   } else {
            //     this.$message.error(res.error);
            //   }
            // });
            // this.chart(val[val.length - 1], "", "", "", "");
        },
        getSelect() {
            // 资质选择的数组
            let quality = this.common.allValueNeed('loan-quality-type');
            this.quality = quality;
            // 获得金额的数组
            this.money = this.common.allValueNeed('fund-needs-scope-type');
            // 新老客户
            this.newOrOld = [
                {
                    cnVal: '新客户',
                    enVal: '',
                    enable: 1,
                    fixed: 0,
                    id: 134,
                    number: 1,
                    remarks: null,
                },
                {
                    cnVal: '老客户',
                    enVal: '',
                    enable: 1,
                    fixed: 0,
                    id: 134,
                    number: 2,
                    remarks: null,
                },
            ];
            //线索未确认标签
            this.unconfirmed = this.common.allValueNeed('clue-unconfirmed-tag');
            // 线索状态
            this.clueStatus = this.common.allValueNeed('clue-status');
            // 客户跟进阶段
            this.clientStageArr = this.common.allValueNeed(
                'client-follow-stage'
            );
            // 意向度
            this.Intentionality =
                this.common.allValueNeed('loan-purpose-level');
            // 获得方式
            this.transferArr = this.common.allValueNeed('client-get-category');
            // 资质星级
            this.starLevelArr = [
                { cnVal: '1星', number: 1 },
                { cnVal: '2星', number: 2 },
                { cnVal: '3星', number: 3 },
                { cnVal: '4星', number: 4 },
                { cnVal: '5星', number: 5 },
            ];
            // 获取部门选项

            departments_staff().then((res) => {
                this.sortArr = res.data.list;
            });
            // 时间排序
            this.timeSortArr = [
                { label: '最后跟进时间从晚到早', value: 1 },
                { label: '最后跟进时间从早到晚', value: 2 },
                { label: '创建时间从晚到早', value: 3 },
                { label: '创建时间从早到晚', value: 4 },
            ];
            // 客户排序
            this.customerTimeSort = [
                { label: '最后跟进时间从晚到早', value: 1 },
                { label: '最后跟进时间从早到晚', value: 2 },
                { label: '入库时间从晚到早', value: 3 },
                { label: '入库时间从早到晚', value: 4 },
                { label: '资金需求从大到小', value: 5 },
                { label: '资金需求从小到大', value: 6 },
            ];
            // 部门海部门池选项
            companySea().then((res) => {
                let data = res.data.list;
                this.departmentSea = data;
            });
            // 封存
            this.sealUpArr = [
                { cnVal: '是', number: 1 },
                { cnVal: '否', number: 2 },
            ];
            this.tagArr = this.common.allValueNeed('clue-invalid-tag');
        },
        getTreeData(data) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (!data[i].children) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        showOpen() {
            this.isOpen = !this.isOpen;
        },
        // 资质选中事件
        selQuality() {
            this.quality.forEach((item) => {
                item.showGet = false;
            });
            this.chooseQuality.forEach((item) => {
                this.quality.forEach((i, index) => {
                    if (item == i.number) {
                        // console.log(item, index);
                        this.quality[index].showGet = true;
                    }
                });
            });
            // console.log(this.quality);
            this.submitData();
        },
        // 金额选中事件
        selMoney() {
            // console.log("选中金钱", this.chooseMoney);
            this.submitData();
        },
        // 未确认选中
        selUnconfirm() {
            // console.log("您选中未确认", this.choiceUnconfirm);

            if (this.choiceUnconfirm == 0) {
                this.misCall = 0;
                this.choiceUnconfirm = '未接';
            } else {
                this.misCall = this.choiceUnconfirm;
            }
            this.submitData();
        },
        // 未确认清除事件
        unconfirmClear() {
            // console.log(this.misCall);
            // console.log(this.choiceUnconfirm);
            (this.choiceUnconfirm = ''), (this.misCall = NaN);
            this.submitData();
        },
        // 线索状态选中
        selClueStatus() {
            // console.log('您选中的线索值',this.choiceClueStatus);
            // console.log(this.misClueStaus);
            if (this.choiceClueStatus == 0) {
                this.misClueStaus = 0;
                this.choiceClueStatus = '新线索';
            } else {
                this.misClueStaus = this.choiceClueStatus;
            }
            this.submitData();
        },
        clearClueStatus() {
            (this.choiceClueStatus = ''), (this.misClueStaus = NaN);
            this.submitData();
        },
        // 新老客户选中
        selNewOrOld() {
            // console.log("您选中的新老客户是", this.chooseNewOrOld);
            // if (this.chooseNewOrOld == 2) {
            //   this.chooseNewOrOld = 0;
            // }
            // console.log("新老客户是", this.chooseNewOrOld);
            this.submitData();
        },
        // 跟进阶段选中
        selClientStage() {
            // console.log("跟进阶段选中", this.clientStage);
            this.submitData();
        },
        // 获得方式选中
        selTransfer() {
            // console.log("获得方式选中", this.transfer);
            this.submitData();
        },
        // 意向度选中
        selIntente() {
            // console.log("意向度选中", this.intentVal);
            if (this.intentVal == 0) this.intentVal = '潜在';
            this.misWanted = 0;
            this.submitData();
        },
        // 线索时间排序
        selCluePublic() {
            // console.log("timeSortVal", this.timeSortVal);
            this.submitData();
        },
        // 客户时间排序
        selCustomer() {
            // console.log("customerTimeSortVal", this.customerTimeSortVal);
            this.submitData();
        },
        // 清除意向度操作
        clearIntente() {
            // console.log("清除意向度", this.intentVal);
            this.intentVal = '';
            this.misWanted = NaN;
            this.submitData();
        },
        // 资质星际选中
        selStarLevel() {
            // console.log("资质星际选中", this.starLevel);
            this.submitData();
        },
        // 选中公司池或者公司海
        selDepartment() {
            // console.log("departSeaVal", this.departSeaVal);
            this.submitData();
        },
        // 选中是否封存
        selSealUp() {
            this.submitData();
        },
        // 标签改变
        tagChange() {
            this.submitData();
        },
        // 提交时间触发传值
        submitData() {
            let data = {
                // 资质
                qualityType: this.chooseQuality.toString(),
                // 金额需求
                fundNeedsScopeType: this.chooseMoney,
                // 未确认
                unconfirmedTag:
                    this.choiceUnconfirm == '未接' ? 0 : this.choiceUnconfirm,
                clueStatus:
                    this.choiceClueStatus == '新线索'
                        ? 0
                        : this.choiceClueStatus,
                // 新老客户
                newClient: this.chooseNewOrOld,
                // 客户跟进阶段
                followStage: this.clientStage,
                // 获得方式
                getCategory: this.transfer,
                // 资质星级
                qualityLevel: this.starLevel,
                // 意向度
                purposeLevel: this.intentVal == '潜在' ? 0 : this.intentVal,
                // 选中部门
                departmentId: this.departmentId,
                // 或者工作人员
                staffId: this.staffId,
                // 线索公共池时间排序
                orderBy: this.timeSortVal,
                // 客户时间排序
                customerOrderBy: this.customerTimeSortVal,
                // 公司池公司海
                departSeaVal: this.departSeaVal,
                // 封存
                sealUpVal: this.sealUpVal == 2 ? 0 : this.sealUpVal,
                // 标签
                tag: this.tag,
            };
            // console.log(data);
            this.$emit('clickData', data);
        },

        // 展开时的几个选中框把他删除
        // 删除资质
        delQuality(item) {
            // console.log(item);
            let index = this.chooseQuality.map((item) => item).indexOf(item);
            this.quality.forEach((items) => {
                if (item == items.number) {
                    items.showGet = false;
                }
            });
            this.chooseQuality.splice(index, 1);
            // console.log("this.chooseQuality", this.chooseQuality);
            this.submitData();
        },
        delMoney() {
            this.chooseMoney = '';
            this.submitData();
        },
        // 删除未确认
        delUnconfirm() {
            this.choiceUnconfirm = '';
            this.misCall = NaN;
            this.submitData();
        },
        // 删除新老客户
        delnewOrOld() {
            this.chooseNewOrOld = '';
            this.submitData();
        },
        delClueStatus() {
            this.choiceClueStatus = '';
            this.submitData();
        },
        // 删除意向度
        delIntentVal() {
            // console.log(this.intentVal);
            this.intentVal = '';
            this.misWanted = NaN;
            this.submitData();
        },
        // 删除客户跟进阶段
        delClientStage() {
            // console.log("我是谁");
            this.clientStage = '';
            this.submitData();
        },
        // 删除获得方式
        delTransfer() {
            this.transfer = '';
            this.submitData();
        },
        // 删除资质星级
        delQualityStar() {
            this.starLevel = '';
            this.submitData();
        },
        // 删除封存
        delSealPool() {
            this.sealUpVal = '';
            this.submitData();
        },
        // 这是点击选中后做的处理由于字典比较多代码有些冗余
        getItem(type, item) {
            if (type == 1) {
                // console.log("this.chooseQuality", this.chooseQuality);
                let index = this.chooseQuality.indexOf(item.number);
                let qualityIndex = this.quality.findIndex((items) => {
                    if (items.number == item.number) {
                        return true;
                    }
                });
                //
                if (this.chooseQuality.indexOf(item.number) == -1) {
                    this.chooseQuality.push(item.number);
                    this.quality[qualityIndex].showGet = true;
                } else {
                    this.chooseQuality.splice(index, 1);
                    this.quality[qualityIndex].showGet = false;
                }
                // console.log("this.chooseQuality", this.chooseQuality);
            }
            if (type == 2) {
                if (this.chooseMoney == item.number) {
                    this.chooseMoney = '';
                } else {
                    this.chooseMoney = item.number;
                }
            }
            if (type == 3) {
                this.misCall = item.number;
                if (this.choiceUnconfirm) {
                    if (
                        item.number == this.choiceUnconfirm ||
                        this.choiceUnconfirm == '未接'
                    ) {
                        this.choiceUnconfirm = '';
                        this.misCall = NaN;
                    } else {
                        if (item.number == 0) {
                            this.choiceUnconfirm = '未接';
                        } else {
                            this.choiceUnconfirm = item.number;
                        }
                    }
                } else {
                    if (item.number == 0) {
                        this.choiceUnconfirm = '未接';
                    } else {
                        this.choiceUnconfirm = item.number;
                    }
                    // console.log("bug", this.choiceUnconfirm);
                }
            }
            if (type == 4) {
                if (this.chooseNewOrOld) {
                    if (item.number == this.chooseNewOrOld) {
                        this.chooseNewOrOld = '';
                    } else {
                        this.chooseNewOrOld = item.number;
                    }
                } else {
                    this.chooseNewOrOld = item.number;
                }
            }
            if (type == 5) {
                if (this.clientStage) {
                    if (item.number == this.clientStage) {
                        this.clientStage = '';
                    } else {
                        this.clientStage = item.number;
                    }
                } else {
                    this.clientStage = item.number;
                }
            }
            if (type == 6) {
                if (this.transfer) {
                    if (item.number == this.transfer) {
                        this.transfer = '';
                    } else {
                        this.transfer = item.number;
                    }
                } else {
                    this.transfer = item.number;
                }
            }
            if (type == 7) {
                this.misWanted = item.number;
                // 有值判断值
                if (this.intentVal) {
                    // 判断点的是不是相同的位置
                    if (item.number == this.intentVal) {
                        this.intentVal = '';
                        this.misWanted = NaN;
                    } else {
                        // 判断是不是点了潜在
                        if (this.intentVal == '潜在' && item.number == 0) {
                            // this.intentVal = "潜在";
                            this.intentVal = '';
                            this.misWanted = NaN;
                        } else {
                            this.intentVal = item.number;
                            if (item.number == 0) this.intentVal = '潜在';
                        }
                    }
                } else {
                    if (item.number == 0) {
                        this.intentVal = '潜在';
                    } else {
                        this.intentVal = item.number;
                    }
                }
            }
            if (type == 8) {
                if (this.starLevel) {
                    if (item.number == this.starLevel) {
                        this.starLevel = '';
                    } else {
                        this.starLevel = item.number;
                    }
                } else {
                    this.starLevel = item.number;
                }
            }
            if (type == 9) {
                if (this.sealUpVal) {
                    if (item.number == this.sealUpVal) {
                        this.sealUpVal = '';
                    } else {
                        this.sealUpVal = item.number;
                    }
                } else {
                    this.sealUpVal = item.number;
                }
            }
            if (type == 10) {
                this.misClueStaus = item.number;
                // 有值判断值
                if (this.choiceClueStatus) {
                    // 判断点的是不是相同的位置
                    if (item.number == this.choiceClueStatus) {
                        this.choiceClueStatus = '';
                        this.misClueStaus = NaN;
                    } else {
                        // 判断是不是点了潜在
                        if (
                            this.choiceClueStatus == '新线索' &&
                            item.number == 0
                        ) {
                            // this.choiceClueStatus = "潜在";
                            this.choiceClueStatus = '';
                            this.misClueStaus = NaN;
                        } else {
                            this.choiceClueStatus = item.number;
                            if (item.number == 0)
                                this.choiceClueStatus = '新线索';
                        }
                    }
                } else {
                    if (item.number == 0) {
                        this.choiceClueStatus = '新线索';
                    } else {
                        this.choiceClueStatus = item.number;
                    }
                }
            }
            this.submitData();
        },
    },
};
</script>

<style lang="less" scope>
.flex {
    display: flex;
}
// /deep/ .el-form-item {
//     width: 14.5%;
//   }
.search {
    font-size: 14px;

    .el-select {
        height: 35px !important;
    }
    .el-form-item {
        margin-bottom: 0px;
        width: 14.5%;
        // width: 23vh;
    }
    .flex-sb {
        display: flex;
        justify-content: space-between;
    }
    .show-btn {
        cursor: pointer;
        align-items: center;
        i {
            font-size: 18px;
        }
    }
    .top {
        border-radius: 4px;
        .el-card__body {
            padding: 0;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
            .selItem {
                background: #f2f2f2;
                border-radius: 5px;
                text-align: center;
                height: 30px;
                line-height: 30px;
                margin-right: 10px;
                color: #aaaaaa;
                padding-left: 13px;
                padding-right: 13px;
                &:hover {
                    color: #4086ec;
                    i {
                        color: #ccc;
                    }
                }
                i:hover {
                    background-color: #ccc;
                    border-radius: 50%;
                    color: #fff;
                }
            }
        }
    }
    .bottom {
        border-radius: 10px;
        .item {
            margin-top: 10px;
            margin-bottom: 10px;
            height: 30px;
            line-height: 30px;
            .item-info {
                height: 30px;
                line-height: 30px;
                .every-item {
                    cursor: pointer;
                    padding: 0 21px;
                    border-radius: 12px;
                    margin-right: 10px;
                    &:hover {
                        background-color: #f2f2f2;
                    }
                }
                .active {
                    color: #4086ec;
                    background-color: #ecf3fd;
                }
            }
        }
    }
    .title-search {
        align-items: center;
        min-height: 40px;
    }
}
</style>
